<template>
	<div style="overflow-x:hidden;">
		<v-row style="padding:40px">
			<img :src="$store.state.icons.icons['favicon-32x32']" alt />
		</v-row>
		<v-row class="align-center justify-center" style="height:40vh">
			<v-col cols="11" sm="2" md="4"></v-col>
			<v-col cols="11" sm="8" md="4">
				<v-card tag="div" elevation="0" color="#F7F9FC">
					<p class="text-sm-center my-heading">Success</p>

					<p class="my-text">
						E-mail has been sent to
						<font>{{this.userdata.email}}</font>
					</p>

					<v-row class="p-0 text-center">
						<v-col cols="12">
							<v-btn class="my-auto-btn" id="my_elevation" depressed to="/login">Back to Login</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col cols="11" sm="2" md="4"></v-col>
		</v-row>
		<v-row class="align-end justify-center" style="height:35vh">
			<v-card tag="div" elevation="0" color="#F7F9FC">
				<p class="my-text mb-2">Haven’t receive an e-mail?</p>
				<p class="custom-link" @click="sendagain">Send again</p>
			</v-card>
		</v-row>
	</div>
</template>


<script>
import Cookies from "js-cookie";
import { AXIOS } from "../../plugins/axios";
export default {
	data() {
		return {
			userdata: {
				email: Cookies.get("email")
			}
		};
	},

	methods: {
		sendagain() {
			AXIOS.post("auth/forget-password", this.userdata)
				.then(resp => {})
				.catch(error => {});
		}
	}
};
</script>

